import "core-js/modules/es6.array.index-of";
var _this = this;
import store from '@/store';
import axios from 'axios';
import util from '@/libs/util';
import Setting from '@/setting';
import router from '@/router';
// import { refreshLogin } from '@/api/account'
import { Message, Notice } from 'view-design';
import _ from 'lodash';
// 创建一个错误
function errorCreate(msg) {
  var err = new Error(msg);
  errorLog(err);
  throw err;
}

// 记录和显示错误
function errorLog(err) {
  // 添加到日志
  store.dispatch('admin/log/push', {
    message: '数据请求异常',
    type: 'error',
    meta: {
      error: err
    }
  });
  // 打印到控制台
  if (process.env.NODE_ENV === 'development') {
    util.log.error('>>>>>> Error >>>>>>');
    console.log(err);
  }
  // 显示提示，可配置使用 iView 的 $Message 还是 $Notice 组件来显示
  if (Setting.errorModalType === 'Message') {
    Message.error({
      content: err.message,
      duration: Setting.modalDuration
    });
  } else if (Setting.errorModalType === 'Notice') {
    Notice.error({
      title: '提示',
      desc: err.message,
      duration: Setting.modalDuration
    });
  }
}

// 创建一个 axios 实例
var service = axios.create({
  baseURL: Setting.apiBaseURL,
  timeout: 100000 // 请求超时时间
});
// 创建一个 axios 实例
var serviceTwo = axios.create({
  baseURL: Setting.apiBaseURL,
  timeout: 100000 // 请求超时时间
});

// 请求拦截器
service.interceptors.request.use(function (config) {
  // token存在就带上
  // 在请求发送之前做一些处理
  var token = util.cookies.get('token');
  var isLogin = config.url.indexOf('login');
  var loginLoginUrl = config.url;

  // const url = config.url === Setting.apiBaseURL + '/app-admin/admin/login'
  // cookie中token没有是自动刷新token
  // if (!url && !token) {
  //   getToken()
  //   return config
  // }
  var userInfo = JSON.parse(window.localStorage.getItem('admin'));
  var userId = '';
  if (_.has(userInfo.sys, 'user["ghost-uuid"].user.info.id')) {
    userId = userInfo.sys.user['ghost-uuid'].user.info.id;
  }
  // token存在且不是登录请求的时候就带上
  if (token && (isLogin === -1 || loginLoginUrl === '/app-admin/loginRecord/list')) {
    if (!JSON.parse(JSON.stringify(config)).headers.Authorization) {
      config.headers.Authorization = 'Bearer ' + token;
    }
  }
  //config.headers.Authorization = 'Bearer ' + ' eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX25hbWUiOiJhZG1pbiIsInNjb3BlIjpbImFsbCJdLCJuaWNrbmFtZSI6bnVsbCwiaWQiOjEsImV4cCI6MTcxOTYyODczNywidHlwZSI6ImFkbWluIiwiYXV0aG9yaXRpZXMiOlsiOV_nrqHnkIblkZgiXSwianRpIjoiMDM5MTlhM2EtMGRhYS00ZDM1LTg0NzgtYjIyOGJjNTUzMDAyIiwiY2xpZW50X2lkIjoiYXBwLWFkbWluIiwidXNlcm5hbWUiOiJhZG1pbiJ9.eCLLd4LDpsbJnzIkshhVwwG-753FTIj8kbMrZBEbyeTRwPG_ZT5vK11uncAaMrosJM8OmRMcr666AC0htLQq5NAd9yGYDWNPUAmtRy1gSlmC9IJ86Qxcm12MeCjYqeX1e22My6YXETJDLZC8W58NgWjIhmisBTxr4NhmK4aZmHE'
  if (userId) {
    config.headers.userId = userId;
  }
  return config;
}, function (error) {
  // 发送失败
  console.log('请求失败', error);
  Promise.reject(error);
});

// 响应拦截器
service.interceptors.response.use(function (response) {
  // dataAxios 是 axios 返回数据中的 data
  var dataAxios = response.data;
  // 这个状态码是和后端约定的
  var code = dataAxios.code;
  // console.log(service.request())
  // 根据 code 进行判断
  if (code === undefined) {
    // 如果没有 code 代表这不是项目后端开发的接口
    return dataAxios;
  } else {
    // 有 code 代表这是一个后端接口 可以进行进一步的判断
    switch (code) {
      case '0':
        return dataAxios;
      case '-1':
        Message.error({
          content: dataAxios.msg || '操作失败'
        });
        return dataAxios;
      case 200:
        // [ 示例 ] code === 0 代表没有错误
        return dataAxios.data;
      case 401:
        router.push('/login');
        errorCreate('身份过期，请重新登录');
        // store.dispatch('admin/account/logout', {
        //   vm: this
        // })
        break;
      case '-10':
        Message.error({
          content: '注册失败'
        });
        break;
      case '-11':
        Message.error({
          content: '验证码已过期'
        });
        break;
      case '-12':
        Message.error({
          content: '用户名异常'
        });
        break;
      case '-13':
        Message.error({
          content: '用户名重复'
        });
        break;
      case '-14':
        Message.error({
          content: '手机号码重复'
        });
        break;
      case '-20':
        Message.error({
          content: '请不要重复提交'
        });
        break;
      case 'xxx':
        // [ 示例 ] 其它和后台约定的 code
        errorCreate("[ code: xxx ] ".concat(dataAxios.msg));
        break;
      default:
        // 不是正确的 code
        console.log(dataAxios);
        errorCreate("".concat(dataAxios.msg));
        break;
    }
  }
  if (code !== '-10' && code !== '-11' && code !== '-12' && code !== '-13' && code !== '-14' && code !== '-20') {
    errorLog(response);
  }
}, function (error) {
  // const requestUrl = _.has(error, 'response.config.url') ? JSON.parse(JSON.stringify(error.response.config.url)) : ''
  // const SettinguRL = Setting.apiBaseURL + '/api/auth/sign/in'
  if (error && error.response) {
    // 如果ckookie中token存在，且状态码未401，则自动跳回登录页
    var cookieToken = util.cookies.get('token');
    if (cookieToken && error.response.status === 401) {
      store.dispatch('admin/account/logout', {
        vm: _this
      });
    }
    switch (error.response.status) {
      case 400:
        error.message = '请求错误';
        break;
      case 401:
        error.message = '未授权该功能！';
        break;
      case 403:
        error.message = '拒绝访问';
        break;
      case 404:
        error.message = "\u8BF7\u6C42\u5730\u5740\u51FA\u9519: ".concat(error.response.config.url);
        break;
      case 405:
        error.message = error.response.data.message || '服务器内部错误';
        break;
      case 408:
        error.message = '请求超时';
        break;
      case 500:
        error.message = error.response.data.message || '服务器内部错误';
        break;
      case 501:
        error.message = '服务未实现';
        break;
      case 502:
        error.message = '网关错误';
        break;
      case 503:
        error.message = '服务不可用';
        break;
      case 504:
        error.message = '网关超时';
        break;
      case 505:
        error.message = 'HTTP版本不受支持';
        break;
      default:
        break;
    }
  }
  errorLog(error);
  return Promise.reject(error);
});

// 处理serviceTwo返回体，目前只使用于刷新token处
serviceTwo.interceptors.response.use(function (response) {
  var dataAxios = response.data;
  if (dataAxios.access_token) {
    return response;
  } else {
    Message.info({
      content: '请重新登录'
    });
    setTimeout(function () {
      store.dispatch('admin/account/logout', {
        vm: _this
      });
    }, 500);
  }
}, function (error) {
  Message.info({
    content: '请重新登录'
  });
  setTimeout(function () {
    store.dispatch('admin/account/logout', {
      vm: _this
    });
  }, 500);
  errorLog(error);
  return Promise.reject(error);
});
export default service;
export { serviceTwo };