import BasicLayout from '@/layouts/basic-layout';
var meta = {
  auth: true
};
export default {
  path: '/thematicManagement',
  name: 'thematicManagement',
  component: BasicLayout,
  redirect: {
    name: 'thematicList'
  },
  meta: meta,
  children: [{
    path: 'thematicList',
    name: 'thematicList',
    meta: {
      title: '专题列表',
      auth: ['thematicManagement', 'thematicList']
    },
    component: function component() {
      return import('@/pages/thematicManagement/index.vue');
    }
  }, {
    path: 'thematicEdit',
    name: 'thematicEdit',
    meta: {
      title: '编辑专题',
      showSider: true,
      auth: ['thematicManagement', 'thematicEdit']
    },
    component: function component() {
      return import('@/pages/thematicManagement/edit.vue');
    }
  }, {
    path: 'thematicCreate',
    name: 'thematicCreate',
    meta: {
      title: '创建专题',
      showSider: true,
      auth: ['thematicManagement', 'thematicCreate']
    },
    component: function component() {
      return import('@/pages/thematicManagement/create.vue');
    }
  }, {
    path: 'imgTextList',
    name: 'imgTextList',
    meta: {
      title: '图文列表',
      auth: ['thematicManagement', 'imgTextList']
    },
    component: function component() {
      return import('@/pages/imgText/index.vue');
    }
  }, {
    path: 'editImgText',
    name: 'editImgText',
    meta: {
      title: '编辑图文',
      showSider: true,
      auth: ['thematicManagement', 'editImgText']
    },
    component: function component() {
      return import('@/pages/imgText/edit.vue');
    }
  }, {
    path: 'imgTexTagtList',
    name: 'imgTexTagtList',
    meta: {
      title: '图文标签分类',
      auth: ['thematicManagement', 'imgTexTagtList']
    },
    component: function component() {
      return import('@/pages/imgTextTag/index.vue');
    }
  }, {
    path: 'log',
    name: 'specialLog',
    meta: {
      title: '操作日志',
      auth: ['thematicManagement', 'specialLog']
    },
    component: function component() {
      return import('@/pages/specialLog/index.vue');
    }
  }]
};