import BasicLayout from '@/layouts/basic-layout';
var meta = {
  auth: true
};
export default {
  path: '/wechatOfficialAccountMessage',
  name: 'wechatOfficialAccountMessage',
  component: BasicLayout,
  redirect: {
    name: 'messageList'
  },
  meta: meta,
  children: [{
    path: 'messageList',
    name: 'messageList',
    meta: {
      title: '公众号消息通知',
      auth: ['activityOperate', 'wechatOfficialAccountMessage', 'messageList']
    },
    component: function component() {
      return import('@/pages/activityOperate/wechatOfficialAccountMessage/messageList');
    }
  }, {
    path: 'updateApp',
    name: 'updateApp',
    meta: {
      title: 'app更新'
    },
    component: function component() {
      return import('@/pages/system/updateApp');
    }
  }, {
    path: 'createMessageList',
    name: 'createMessageList',
    meta: {
      title: '公众号消息通知',
      showSider: true,
      auth: ['activityOperate', 'wechatOfficialAccountMessage', 'createMessageList']
    },
    component: function component() {
      return import('@/pages/activityOperate/wechatOfficialAccountMessage/messageList/createMessageList');
    }
  }, {
    path: 'messageSendLog',
    name: 'messageSendLog',
    meta: {
      title: '消息发送日志',
      showSider: true,
      auth: ['activityOperate', 'wechatOfficialAccountMessage', 'messageSendLog']
    },
    component: function component() {
      return import('@/pages/activityOperate/wechatOfficialAccountMessage/messageSendLog');
    }
  }, {
    path: 'notifyChannel',
    name: 'notifyChannel',
    meta: {
      title: '通知渠道',
      auth: ['activityOperate', 'wechatOfficialAccountMessage', 'notifyChannel']
    },
    component: function component() {
      return import('@/pages/activityOperate/wechatOfficialAccountMessage/notifyChannel');
    }
  }, {
    path: 'createNotifyChannel',
    name: 'createNotifyChannel',
    meta: {
      title: '创建通知渠道',
      showSider: true,
      auth: ['activityOperate', 'wechatOfficialAccountMessage', 'createNotifyChannel']
    },
    component: function component() {
      return import('@/pages/activityOperate/wechatOfficialAccountMessage/notifyChannel/createNotifyChannel');
    }
  }]
};